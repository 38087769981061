import {
  getGlobalParamsOptionsAsync,
  getCommonParamsOptions
} from '@/utils/common'

export const baseFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.role-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'groups',
      type: 'select',
      label: 'user.resource-group',
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('groupList', {
          isNeedPreFix: false
        }),
      otherOptions: {
        multiple: true,
        disabled: true
      }
    },
    {
      field: 'type',
      type: 'select',
      label: 'user.role-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('role_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'user.role-description',
      otherOptions: {
        disabled: true
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ]
}
